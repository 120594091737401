import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { DictionaryContext, LayoutContext } from '../contexts'
import { getPathname } from '../utils/index'
import hreflang from '../static/hreflang.json'

const SEO = ({ title, description, image, slug, isProduct, isPage }) => {
  const dictionary = useContext(DictionaryContext)
  const { node_locale } = useContext(LayoutContext)
  const ogImage = image || dictionary.seoFallbackImage.file.url
  const seoDescription = description || dictionary.seoFallbackDescription
  const redirect = Object.keys(hreflang)

  return (
    <Helmet>
      <html lang={node_locale} />
      <title>{title}</title>
      <meta name='description' content={seoDescription} />
      <meta name='image' content={`https:${ogImage}`} />
      <link
        rel='canonical'
        href={`https://getsuperfluid.com${getPathname(node_locale, slug)}`}
      />
      {redirect.map((locale) =>
        hreflang[locale].map((country, index) => (
          <link
            key={index}
            rel='alternate'
            hrefLang={country}
            href={`https://getsuperfluid.com${getPathname(node_locale, slug)}`}
          />
        ))
      )}

      <meta
        property='og:url'
        content={`https://getsuperfluid.com${getPathname(node_locale, slug)}`}
      />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={seoDescription} />
      <meta property='og:image' content={`https:${ogImage}`} />
      {isPage && <meta property='og:type' content='page' />}
      {isProduct && <meta property='og:type' content='product' />}
      <meta name='google-site-verification' content='escgF6QtXwsesQmwlVmKkiCtSa2PZUNW2PsbBg9B3yM' />
    </Helmet>
  )
}

export default SEO
